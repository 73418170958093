.alert {
  padding: 5px;
  text-align: center;
  &.callout {
    background-color: var(--highlight-color);
    color: var(--card-background-color);
  }
  &.success {
    background-color: var(--success);
    color: var(--card-background-color);
  }
  &.warning {
    background-color: var(--warning);
    color: var(--card-background-color);
  }
  &.danger {
    background-color: var(--danger);
    color: var(--card-background-color);
  }
}
