.admin {
  .search {
    margin-bottom: 10px;
    width: calc(100% - 5px);
  }
  .heading {
    h1 {
      font-size: 1.5em;
      padding: 0 5px 15px;
      border-bottom: 1px solid var(--border-color);
      small {
        opacity: 0.5;
        font-size: 0.65em;
      }
    }
    margin-bottom: 20px;
  }
}
