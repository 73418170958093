body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0,0,0,.75);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
}

body::-webkit-scrollbar {
  display: none;
}

h1,
h2,
h3 {
  color: black;
}

h3 {
  font-size: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fade-in {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut 1s;
}


@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

:root {
  --primary-color: rgb(0, 111, 202);
  --highlight-color: rgb(0, 187, 255);
  --border-color: #e5e7eb;
  --success: #2A9D8F;
  --card-background-color: #fff;
  --disabled: #eaeaea;
  --danger: rgb(241, 65, 65);
  --warning: #F58A07;
  --default: black;
  --font-color: rgb(34, 34, 34);
  --body-background: rgb(243, 244, 246);
}

.callout {
  color: var(--highlight-color);
}