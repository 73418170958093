.baseline {
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  .button {
    &.disabled {
      img {
        filter: (invert(0));
        opacity: 0.5;
      }
    }
  }

  &.baseline-padding {
    padding: 15px 20px;
  }

  h1 {
    margin-left: 5px;
  }

  table {
    padding-bottom: 40px;
    td {
      padding: 10px 0;
      &.large {
        font-size: 2em;
        width: 30px;
      }
    }
  }

  .logo {
    font-size: 1em;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .avatar {
    margin-top: 10px;
    height: 80px;
    width: 80px;
    border-radius: 80px;
    object-fit: cover;
  }

  .user-data {
    text-align: center;
    font-weight: 800;
    img {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    h1 {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
  }
  .button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &.short {
      width: 40px;
      padding: 0;
      justify-content: flex-start;
      background-color: transparent;

      img {
        filter: invert(0);
        transform: rotate(90deg);
      }
    }

    .icon-text {
      display: flex;
      align-items: center;
      small {
        margin-right: 5px;
      }
    }
    img {
      filter: invert(1);
      height: 20px;
      width: 20px;

      &.next {
        transform: rotate(-90deg);
      }
    }
  }

  .onboarding-body {
    padding: 5px;
  }

  @media screen and (max-width: 768px) {
    &.baseline.baseline-padding {
      padding-bottom: 60px;
    }
    padding-bottom: 60px;
  }
}
