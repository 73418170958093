.meta {
  font-size: 0.6em;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 800;
  display: flex;
  flex-wrap: wrap;
  opacity: 0.75;
  margin: 0 -5px;

  div {
    padding: 5px;
  }
}
.elimination-detail {
  h1 {
    font-size: 1.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    small {
      font-size: 0.6em;
    }

    span {
      display: flex;
      align-items: center;
    }

    img {
      height: 25px;
      width: 25px;
      transform: rotate(90deg);
      opacity: 0.5;
    }
  }
  .card {
    padding: 0 10px;
    align-items: flex-start;

    &.add-missing {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      height: 200px;
      width: 200px;
      line-height: 1.25;
      flex-direction: column;
      margin: 40px auto;

      img {
        opacity: 0.5;
        margin-bottom: 10px;
      }
    }

    h2 {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
  }
}

.elimination {
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    height: 80x;
    opacity: 0.5;
  }
  .add-new {
    max-width: 40vw;
    padding: 15px !important;
    text-align: center;
    img {
      margin-bottom: 10px;
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
  }
  .grid-item {
    padding: 10px;
    cursor: pointer;

    .elimination-line-item-container {
      margin-top: 5px;
      max-height: 208px;
      overflow: hidden;
    }
  }
  .tip {
    padding: 0 10px 10px;
    //font-size: 0.9em;
    //line-height: 1.25;
  }
  h2 {
    font-size: 2em;
    display: flex;
    align-items: center;
    margin: 0;

    small {
      margin-left: 5px;
    }
  }
  h3 {
    margin: 2.5px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      height: 20px;
      width: 20px;
      transform: rotate(-90deg);
      opacity: 0.25;
    }
  }
}
