.home {
  .danger {
    color: var(--danger);
  }
  .warning {
    color: var(--warning);
  }
  .success {
    color: var(--success);
  }
  .logo {
    height: 36px;
    width: 80px;
    margin: 15px 18px 2.5px;
  }
  .logo-font {
    margin-top: 10px;
    margin-left: 13px;
  }
  .doctors {
    h3 {
      a {
        max-height: 43px;
        overflow: hidden;
        align-items: flex-start;
      }
    }
  }
  .container {
    margin-bottom: 40px;
  }
  .chart {
    .card {
      margin: 5px;
      min-width: 300px;

      &:first-child {
        margin-left: 15px;
      }
      &:last-child {
        margin-right: 15px;
      }
    }

    h1 {
      margin: 5px 15px;

      a {
        font-size: 0.75em;
      }
    }
    .charts-container {
      display: flex;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  h6 {
    font-size: 1em;
    padding: 0 5px;
    margin: 0 0 40px 12px;

    b {
      opacity: 0.5;
      //color: var(--highlight-color);
    }
  }
  .elimination {
    &.horizontal {
      h1 {
        font-size: 1.25em;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          margin-right: 15px;
          color: var(--primary-color);
          font-size: 0.75em;
          text-decoration: none;
        }
      }
      .grid {
        display: flex;
        height: fit-content;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }

        .grid-item {
          min-width: 200px;

          &.active {
            border: none;
          }

          &:first-child {
            margin-left: 15px;
          }
          &:last-child {
            margin-right: 15px;
          }
        }
      }
      .cardGrid__item {
        &:first-child {
          margin-left: 15px;
        }
        &:last-child {
          margin-right: 15px;
        }
      }
    }
  }
}
