.placeholder {
  text-align: center;
  width: calc(100vw - 20px);
  margin: 40px auto;

  h1 {
    padding: 20px;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.25;
  }

  .button {
    margin: auto;
    width: 80%;
  }
}
