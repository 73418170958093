.collapse {
  background-color: var(--card-background-color);
  margin-top: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0;
  .title {
    padding: 10px;
    font-weight: 800;
    font-size: 1.25em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      height: 20px;
      width: 20px;
      transform: rotate(-90deg);
      transition: 0.15s ease-in-out;
      opacity: 0.25;

      &.active {
        transform: rotate(0deg);
      }
    }
  }
  .label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.5px;
    color: orange;
    margin-bottom: 5px;
  }

  .line-item {
    display: flex;
    padding: 10px;
  }
}
