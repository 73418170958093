.page-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  .tab {
    padding: 5px 15px;
    border-bottom: 3px solid var(--border-color);
    min-width: 100px;
    text-align: center;
    font-weight: 800;
    cursor: pointer;

    &.active {
      border-color: var(--primary-color);
    }
  }
}
