.chart {
  padding-left: 0;
  padding-right: 0;
  flex-direction: column;
  .charts-container {
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  h1 {
    margin-left: 15px;
    margin-bottom: 0;
  }

  &.page {
    .charts-container {
      margin-top: 20px;
    }
  }

  &.horizontal {
    h1 {
      font-size: 1.25em;
      display: flex;
      justify-content: space-between;
      align-items: center;

      small {
        font-size: 0.6em;
      }

      span {
        display: flex;
        align-items: center;
      }

      img {
        height: 25px;
        width: 25px;
        transform: rotate(90deg);
        opacity: 0.5;
      }
    }
  }

  .card {
    flex-direction: column;
    min-width: 320px;
    margin-right: 10px;
    margin: 5px;
    height: 220px;

    &:first-child {
      margin-left: 15px;
    }
    &:last-child {
      margin-right: 15px;
    }
  }

  .single-chart {
    h3 {
      margin: 0;
    }
    height: 300px;
    width: 100%;
    text-align: center;
  }
}

.chart-page-container {
  display: flex;
  flex-direction: column;

  .elimination {
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      grid-auto-flow: column;
      grid-auto-columns: minmax(160px, 1fr);
      overflow-x: auto;

      .grid-item {
        .elimination-line-item-container {
          max-height: fit-content;
        }
        &:first-child {
          margin-left: 15px;
          opacity: 0.25;
        }
        &:last-child {
          margin-right: 15px;
        }
      }
    }
    h1 {
      display: none;
    }
  }

  .chart {
    height: 34vh;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .chart-companion {
    overflow-y: scroll;
    height: 56vh;
    &::-webkit-scrollbar {
      display: none;
    }
    p {
      padding: 0 15px;
    }
    .elimination .grid-item {
      font-size: 0.9em !important;
    }
  }
}
