.button {
  align-items: center;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 800;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 15px;
  background-color: var(--primary-color);
  color: white;

  &.disabled {
    background-color: var(--disabled);
    color: var(--default);
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.link {
    background-color: transparent;
    color: initial;
    width: fit-content;
    color: var(--primary-color);
    font-weight: 800;
    padding: 5px;
  }

  &.success {
    background-color: var(--success);
    color: var(--card-background-color);
  }
}
