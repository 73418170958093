.header {
  h1 {
    font-size: 1.25em;
    display: flex;
    //justify-content: space-between;
    align-items: center;

    small {
      font-size: 0.6em;
    }

    span {
      display: flex;
      align-items: center;
    }

    div {
      cursor: pointer;
    }

    img {
      height: 25px;
      width: 25px;
      transform: rotate(90deg);
      opacity: 0.5;
      cursor: pointer;
    }
  }
}
