.card {
  border-radius: 6px;
  padding: 15px 10px;
  background-color: var(--card-background-color);
  display: flex;
  align-items: center;
  margin: 15px 0;
  line-height: 0.75;

  h2 a,
  .header-link {
    font-size: 16px;
  }

  h3 {
    color: var(--primary-color);
  }

  &.card-vertical {
    flex-direction: column;
    .item {
      width: 100%;
      padding: 5px 0;
      .line-item {
        padding: 10px 0;
        line-height: 1.25;
      }
    }
  }

  &.active {
    ion-icon {
      opacity: 1;
      color: var(--success);
    }
  }
  .icon {
    margin: 7.5px 15px 7.5px 7.5px;
    display: flex;
    justify-content: center;
  }

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

  .label {
    line-height: 1.25;
    display: flex;

    flex-direction: column;

    h5 {
      margin: 0 0 5px;
      margin-top: 5px;
      line-height: 1;
    }
  }

  ion-icon {
    opacity: 0.25;
    font-size: 1.5em;
  }
}
