nav {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;

  h6 {
    opacity: 0.5;
    margin-top: -5px !important;
    margin-bottom: 10px !important;
    font-size: 1em !important;
    padding: 0 5px !important;
    margin-left: 4px !important;
  }

  .menu {
    cursor: pointer;
  }

  .user-details {
    margin: 15px 10px 5px;

    img {
      height: 40px;
      width: 40px;
      border-radius: 40px;
    }
  }

  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50px;

    img {
      height: 34px;
      width: 34px;
    }
  }

  .logo-font {
    padding: 10px 0;
  }

  img {
    height: 30px;
    width: 30px;
  }

  .drawer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--card-background-color);
    padding: 0px 8px;
    display: flex;
    flex-direction: column;

    .logo {
      height: 36px;
      width: 80px;
      margin: 15px 18px 2.5px;
      margin-left: 10px;
      margin-bottom: 12px;
    }

    a {
      margin: 7.5px 10px;
      font-size: 1.25em;
      text-decoration: none;
      color: var(--default);
      font-weight: 800;
    }

    .feedback {
      a {
        display: block;
        font-size: 14px;
        font-weight: 400;
      }

      .mobile {
        display: none;
      }

      .desktop {
        display: block;
      }
      @media screen and (max-width: 700px) {
        .mobile {
          display: block;
        }

        .desktop {
          display: none;
        }
      }
    }
  }
}
