.questionaire {
  margin-bottom: 60px;

  .line-item {
    border: none;
  }
}

.elimination {
  .scored {
    max-height: 80px;
    overflow: hidden;
  }
}

.symptoms-detail {
  .placeholder h1 {
    justify-content: center;
  }
  .card {
    position: relative;
  }
  .top-right {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  h5 {
    margin: 10px 0;
  }
  h1 {
    font-size: 1.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    small {
      font-size: 0.6em;
    }

    span {
      display: flex;
      align-items: center;
    }

    img {
      height: 25px;
      width: 25px;
      transform: rotate(90deg);
      opacity: 0.5;
    }
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 0.9em;
  }
}

.score {
  font-size: 3em;
  display: flex;
  align-items: flex-end;
  line-height: 0.5;
  justify-content: center;
  margin-bottom: 10px;
  height: 0px;

  .faded {
    margin-left: 5px;
  }

  .danger {
    color: var(--danger);
  }
  .warning {
    color: var(--warning);
  }
  .success {
    color: var(--success);
  }
  small {
    font-size: 0.5em;
  }
  .item {
    h4 {
      margin-bottom: 5px;
    }
  }
}

label {
  margin-bottom: 5px;
  display: block;
}
.form-control {
  padding: 7.5px 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: var(--card-background-color);
  margin-bottom: 10px;
}

.points {
  display: flex;
  margin-bottom: 10px;
  margin-top: 5px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
  .point-item {
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background-color: var(--border-color);
    &.active {
      background-color: var(--success);
      color: var(--card-background-color);
    }

    &.success {
      background-color: var(--success);
      color: var(--card-background-color);
    }
    &.danger {
      background-color: var(--danger);
      color: var(--card-background-color);
    }
    &.warning {
      background-color: var(--warning);
      color: var(--card-background-color);
    }
  }
}

.total-score {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: var(--card-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-top: var(--border-color);
  .button {
    font-size: 1.05em;
    width: 100%;
  }
}

.sassy--select {
  border: none;
  border-radius: 0.25rem;
  display: inline;
  padding: 0.2rem 0.4rem;
}
.sassy--select__container {
  border-radius: 0.25rem;
  position: relative;
}
.sassy--select:focus {
  box-shadow: var(--ring-shadow);
  outline: none;
}
.sassy--select.sassy--select__disabled {
  opacity: var(--disabled-opacity);
  pointer-events: none;
}
.sassy--select.sassy--select__disabled:focus {
  box-shadow: none;
}
.sassy--select__dropdown {
  background: var(--select-background);
  border: none;
  border: var(--outline);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-md);
  display: flex;
  flex-direction: column;
  max-height: 40vh;
  overflow: auto;
  padding: 0.5rem;
  position: absolute;
  scrollbar-width: none;
  top: 1.5rem;
  z-index: 2;
}
.sassy--select__dropdown::-webkit-scrollbar {
  display: none;
}
.sassy--option,
.sassy--select {
  font-family: var(--font);
  font-size: 1rem;
  font-weight: 500;
}
.sassy--option:focus {
  box-shadow: var(--ring-shadow);
  outline: none;
}
.sassy--option__disabled {
  color: var(--disabled-color);
  pointer-events: none;
}
.sassy--select:hover {
  cursor: context-menu;
}
.sassy--option {
  background: var(--option-background);
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-top: 0.25rem;
  padding: 0.25rem 0.4rem;
  transition: all 0.1s ease;
}
.sassy--option:first-of-type {
  margin-top: 0;
}
.sassy--option:hover,
.sassy--option__active {
  background-color: var(--theme-color);
  box-shadow: var(--shadow);
  color: var(--selected-date-color);
}
.sdp {
  background: var(--background);
  border: none;
  border: var(--outline);
  border-radius: 0.5rem;
  box-shadow: var(--shadow);
  padding: 0.75rem;
  width: 16rem;
}
.sdp--disabled {
  pointer-events: none;
}
.sdp--month-name:focus,
.sdp--square-btn:focus,
.sdp:focus {
  box-shadow: var(--ring-shadow);
  outline: none;
}
.sdp--month-name {
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.sdp--text {
  font-family: var(--font);
  font-weight: 500;
  margin: 0;
}
.sdp--text__inactive {
  color: var(--disabled-color);
  pointer-events: none;
}
.sdp--square-btn {
  align-items: center;
  background-color: var(--btn-background);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  font-family: var(--font);
  height: 1.8rem;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  width: 1.8rem;
}
.sdp--square-btn__outlined,
.sdp--square-btn__outlined:focus:hover {
  border: var(--outline);
}
.sdp--square-btn:focus:hover {
  box-shadow: none;
}
.sdp--square-btn__shadowed,
.sdp--square-btn__shadowed:focus:hover {
  box-shadow: var(--shadow);
}
.sdp--date-btn__selected {
  background-color: var(--theme-color);
  box-shadow: var(--shadow);
  color: var(--selected-date-color);
}
.sdp--date-btn__selected.sdp--square-btn:focus {
  box-shadow: var(--shadow);
}
.sdp--header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.sdp--grid {
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  margin: 0.5rem 0 0;
}
.sdp--grid > p.sdp--text {
  padding: 0.5rem 0;
}
.sdp--header__main {
  align-items: center;
  display: flex;
  font-weight: 700;
  justify-content: center;
}
.sdp--select__month button {
  text-align: left;
}
.sdp,
.stp {
  --font: inherit;
  --shadow: transparent 0px 0px 0px 0px, transparent 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  --shadow-md: transparent 0px 0px 0px 0px, transparent 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  --theme-color: #60a5fa;
  --text-color: #1f1f1f;
  --background: #fff;
  --btn-background: #fff;
  --select-background: #fff;
  --option-background: #fff;
  --selected-date-color: #fff;
  --disabled-color: #bdbdbd;
  --disabled-opacity: 50%;
  --outline: 1px solid #cbd5e1;
  --ring-shadow: 0px 0px 0px 2px rgba(97, 166, 250, 0.7);
  background: var(--background);
  color: var(--text-color);
}
.stp {
  border: none;
  border: var(--outline);
  border-radius: 0.5rem;
  box-shadow: var(--shadow);
  display: inline-flex;
  font-family: var(--font);
  outline: none;
  padding: 0.5rem 0.4rem;
}
.stp--disabled {
  pointer-events: none;
}
.stp--divider {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.stp--divider__disabled {
  opacity: var(--disabled-opacity);
}
.stp * {
  outline: none;
}
.stp:focus {
  box-shadow: var(--ring-shadow);
}
.stp > p {
  display: inline;
  margin: 0 2px;
}
