.login {
  &.button {
    img {
      height: 20px;
      width: 20px;
      filter: invert(1);
      margin-right: 10px;
    }
  }
}
