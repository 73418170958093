.labs {
  .placeholder {
    img {
      height: 80px;
    }
  }
}

.add-labs,
.labs {
  .danger {
    color: var(--danger);
    font-size: 0.75em;
    font-weight: 800;
  }

  .success {
    color: var(--success);
    font-size: 0.75em;
    font-weight: 800;
  }

  .line-item {
    display: flex;
    align-items: center;

    .option {
      margin-right: 15px;
    }

    &.lab-item {
      flex-direction: column;
      align-items: flex-start;

      .input-group {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 10px;
        font-weight: 800;

        input {
          margin-right: 10px;
          width: 84px;
        }
      }
    }

    img {
      margin: 0 3px -5px 0;
    }
  }
}
