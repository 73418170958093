.landing {
  .preview {
    padding: 30px;
    max-width: 280px;
    margin: 0 auto 40px;
    border-radius: 8px;
  }
  .process {
    background-color: var(--card-background-color);
    text-align: center;
    padding: 2vh 10px 4vh;
    img {
      width: 100%;
      margin: auto;
    }
  }
  .inflammation {
    padding: 15px;
    img {
      width: 100%;
      border-radius: 8px;
      margin-top: 10px;
    }
  }

  h1 {
    small {
      color: var(--highlight-color);
    }
  }

  h5 {
    padding: 0 15px 15px;
    text-align: center;
    font-size: 1em;
    font-weight: 400;
    margin: 0;
  }

  .issue {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    height: 20px;
    font-size: 1.5em;
  }

  .toggleOut {
    animation: fadeOut 500ms;
    opacity: 0;
  }

  .toggleIn {
    animation: fadeIn 1s;
    opacity: 1;
  }
  .bottom {
    padding: 15px;
    text-align: center;
    display: block;
    .button {
      max-width: 420px;
      margin: 0 auto 10px;
    }

    .links {
      margin: 20px auto 0;
      display: flex;
      justify-content: space-between;
      font-size: 0.75em;

      @supports (-webkit-touch-callout: none) {
        padding-bottom: 80px;
      }
    }
  }
  h2 {
    text-align: center;
    margin: 40px auto 10px;
  }
  .steps {
    padding: 10px;
    .panel {
      border-radius: 8px;
      margin-bottom: 10px;
      height: 300px;
      background-position: topcenter;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: flex-end;
      padding: 15px;
      //align-items: center;
      flex-direction: column;
      //text-align: center;
      position: relative;
      overflow: hidden;

      .overlay {
        border-radius: 8px;
        overflow: hidden;
        height: 300px;
        padding: 15px;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0.95) 30%,
          rgba(255, 255, 255, 0.75) 50%,
          rgba(255, 255, 255, 0.1) 70%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      h3 {
        display: flex;
        align-items: flex-end;
        margin-bottom: 7.5px;
        span {
          margin-right: 7.5px;
          font-size: 2em;
          font-weight: 800;
          margin-bottom: -5px;
          color: var(--highlight-color);
        }
      }
      h3,
      p {
        position: relative;
        z-index: 4;
      }
    }
  }
  .hero {
    height: 100vh;
    background-position: center;
    text-align: center;
    position: relative;

    .hero-content {
      position: absolute;
      top: 5vh;
      text-align: center;
      left: 0;
      right: 0;
      z-index: 2;
    }

    .cta {
      position: absolute;
      bottom: 7.5vh;
      z-index: 2;
      left: 0;
      right: 0;
      padding: 0 20px;

      .button {
        max-width: 600px;
        margin: 0 auto 10px;
      }
      h3 {
        //color: var(--card-background-color);
        margin: 7.5px 0;
      }
    }

    video {
      height: 100vh;
      object-fit: cover;
    }

    h1 {
      font-size: 1.25em;
      line-height: 1.5;
      //color: var(--primary-color);

      span {
        padding: 10px;
      }
    }
  }
  .button {
    margin-bottom: 5px;
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9) 20%,
    rgba(255, 255, 255, 0.2) 45%,
    rgba(255, 255, 255, 0) 100%
  );
}

.overlay-bottom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  z-index: 1;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9) 20%,
    rgba(255, 255, 255, 0.2) 45%,
    rgba(255, 255, 255, 0) 100%
  );
}

@media screen and (min-width: 767px) {
  .steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .panel {
      max-width: 300px;
      margin: 10px;
    }
  }
  .bottom {
    display: none;
  }
  .hero {
    .hero-content {
      .issue {
        top: 80px;
      }
      h1 {
        font-size: 3em;
        line-height: 1.5;
        //color: var(--primary-color);

        span {
          padding: 40px 10px;
        }
      }
    }
  }
}
