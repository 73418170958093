.doctors {
  .grid {
    .grid-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .line-item {
        width: 100%;
        padding: 10px 0;
        border: none;
        font-size: 12px;
        line-height: 1.5;

        a {
          justify-content: center;
        }
      }

      a {
        color: var(--default);
        margin: 2.5px 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        text-transform: capitalize;
      }
      small {
        a {
          white-space: nowrap;
          font-size: 12px;
        }
      }

      h3 {
        a {
          color: var(--primary-color);
          font-size: 0.95em;
          align-items: flex-start;
        }
      }

      .avatar-container {
        height: 130px;
        width: 130px;
        border-radius: 130px;
        background-color: var(--highlight-color);
        background-size: cover;
        margin: 10px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2em;
        color: rgba(255, 255, 255, 0.2);
      }

      .ifm-logo {
        height: 20px;
        width: 20px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}
