@import url('https://fonts.googleapis.com/css?family=Inter:500,700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Zetta:wght@700&family=Palanquin+Dark:wght@700&family=Yeseva+One&display=swap');

.App {
  font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(243, 244, 246);
  height: 100vh;
  width: 100%;
  overflow: auto;
  position: relative;
  font-size: 16px;
  /* background-color: var(--primary-color);
  background-image: linear-gradient(
    -160deg,
    var(--primary-color) 60%,
    var(--highlight-color) 100%
  ); */
  &::-webkit-scrollbar {
    display: none;
  }
}

.link {
  cursor: pointer;
}

.line-item {
  display: block;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #eee;

  img {
    &.success {
      opacity: 1;
      filter: invert(0.5) sepia(1) saturate(5) hue-rotate(105deg);
    }
    opacity: 0.5;
    filter: invert(0.5) sepia(1) saturate(0) hue-rotate(175deg);
  }

  &:first-child {
    border-top: 1px solid #eee;
  }

  &:last-child {
    border: none;
  }
  img {
    width: 22.5px;
    height: 22.5px;
    margin-right: 5px;
  }
}

input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pill {
  font-size: 0.5em;
  vertical-align: middle;
  padding: 2.5px 7.5px;
  border-radius: 10px;
  background-color: var(--primary-color);
  color: rgb(243, 244, 246);
  line-height: 1.5;
}

.warning {
  color: var(--warning);
}

.success {
  color: var(--success);
}

.danger {
  color: var(--danger);
}

.logo-font {
  font-family: 'Lexend Zetta';
}
.logo {
  font-size: 2em;
  //color: var(--highlight-color);
  line-height: 1;
  color: var(--default);
}

h1 {
  font-weight: 700;
  margin: 0 0 10px 0;

  &.header {
    font-size: 1.25em;
    margin-bottom: 20px;
  }
}

.content {
  padding: 0 20px;
}

small {
  font-size: 0.85em;
}

p {
  margin: 0 0 10px 0;
  line-height: 1.25;
}

.date-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  overflow: scroll;

  div {
    padding: 15px;

    opacity: 0.15;
    font-weight: 700;

    &.active {
      opacity: 1;
    }
  }
}

.page {
  padding: 10px;
}

.page,
.home {
  animation: fadeIn 1s;

  @media screen and (max-width: 767px) {
    padding-bottom: 60px;
  }
}

.page-with-footer {
  padding-bottom: 66px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--card-background-color);
  padding: 10px;
  border-top: 1px solid var(--border-color);
  .button {
    font-size: 1.05em;
  }
}

.dflex {
  display: flex;
  align-items: center;
  img {
    height: 25px;
    margin-right: 5px;
  }
}

.faded {
  opacity: 0.5;
}

.form-item {
  label {
    font-weight: 800;
  }
}

.floating-button {
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  height: 50px;
  width: 50px;
  border-radius: 50px;

  img {
    height: 35px;
    width: 35px;
    filter: invert(1);
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 0.5em;
  .grid-item {
    background-color: white;
    border-radius: 8px;
    padding: 0px 15px;
    min-height: 100px;

    &.add-new {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--primary-color);
      font-weight: 800;

      img {
        opacity: 0.25;
      }
    }

    h3 {
      //color: var(--primary-color);
    }

    &.grid-button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 35px;
        height: 35px;
        opacity: 0.5;
      }
    }
  }
}

a {
  color: var(--primary-color);
  font-weight: 800;
  text-decoration: none;
}

.div-table {
  display: block;
  border-top: 1px solid var(--border-color);
  white-space: nowrap;
}
.div-row {
  display: flex;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  width: 100%;
  border-left: 1px solid var(--border-color);
}
.div-cell {
  display: inline-block;
  padding: 5px;
  border-right: 1px solid var(--border-color);
  white-space: pre-wrap;
  word-break: break-all;
  min-width: 300px;
  width: 100%;
  &.short {
    min-width: 60px;
  }
}
