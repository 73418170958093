.grid {
  .grid-item.grid-item-elimination {
    &.active {
      border: 3px solid var(--highlight-color);
    }
  }
}
.calendar {
  .date {
    display: flex;

    margin: 5px 0 10px 0;
  }
  small {
    font-size: 0.85em;
  }
  .grid {
    .grid-item.grid-item-elimination {
      h3 {
        cursor: auto;
        color: var(--default);
      }
      .line-item {
        border: none;
        border-top: 1px solid var(--border-color);

        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: fit-content;
          color: var(--primary-color);
        }

        img {
          &.next {
            height: 20px;
            width: 20px;
            transform: rotate(-90deg);
            margin: 0;
          }
        }
        a {
          display: flex;
          align-items: center;
        }
        h4 {
          margin: 0;
        }
      }
    }
  }
}
